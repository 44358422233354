import pathToRegexp from 'path-to-regexp';
import { ExtractRouteParams, generatePath } from 'react-router';

import { PathParameterTakingFunction } from './types';

export * from './deprecated-dialogs';

type PathDefinition<Path extends `/${string}`> = {
	/**
	 * The path to be passed to a <Route />.
	 *
	 * This is the path containing route parameter matchers,
	 * so do NOT redirect to it.
	 */
	path: Path;

	/**
	 * A method to build a version of this path you may redirect
	 * to.
	 *
	 * This can be used in redirects and links etc.
	 */
	build: PathParameterTakingFunction<Path, `/${string}`>;

	/**
	 * The same regex react-router would use to match this
	 * route.
	 *
	 * You can use this via `path.regex.test(locat
	 * ion.pathname)`
	 * if you for some reason need to figure out if this route
	 * matches a given path.
	 */
	regex: RegExp;
};

const buildPath = <Path extends `/${string}`>(path: Path): PathDefinition<Path> => ({
	path,
	build: (params?: ExtractRouteParams<Path>) => generatePath(path, params),
	regex: pathToRegexp(path, { end: false }),
});

export const accountManagementPath = buildPath('/controlpanel');
export const eventlistPath = buildPath('/history');
export const devicePoolPath = buildPath('/device-pool');
export const microsoftTeamsPath = buildPath('/ms-teams');
export const hardwarePath = buildPath('/hardware/:phoneId(\\d+)?');
export const blocklistPath = buildPath('/blocklist');
export const personalSettingsPath = buildPath('/personal-settings');
export const userNotificationsPath = buildPath('/user-notifications');
export const telephonySettingsPath = buildPath('/settings');
export const groupsPath = buildPath('/groups');
export const groupPath = buildPath(`${groupsPath.path}/:groupId(g\\d+)`);

export const csvMappingPath = buildPath(`/csv-mapping/:filePseudo/:textPseudo/:type`);

export const groupPathPhonenumber = buildPath(`${groupsPath.path}/:groupId(r\\d+)`);

export const groupRoutingPath = buildPath(`${groupPath.path}/routing`);
export const groupStatisticsPath = buildPath(`${groupPath.path}/statistics`);
export const unlinkedDevicesPath = buildPath('/unlinkeddevices');
export const unlinkedDevicePath = buildPath(`${unlinkedDevicesPath.path}/:deviceId(e\\d+)`);
export const mobileTelephonyPath = buildPath('/mobile-telephony');
export const mobileTelephonyDevicePath = buildPath(
	`${mobileTelephonyPath.path}/:webuserId(w\\d+)/:deviceId`
);

export const mobileTelephonyDevicePathPhonenumber = buildPath(
	`${mobileTelephonyPath.path}/:webuserId(y\\d+)/:deviceId`
);

export const usersPath = buildPath('/users');
export const userPath = buildPath(`${usersPath.path}/:webuserId(w\\d+)`);
export const userRoutingPath = buildPath(`${userPath.path}/routing`);
export const routingPath = buildPath('/routing');
export const conferenceRoomsPath = buildPath('/conferenceroom');
export const conferenceRoomPath = buildPath(`${conferenceRoomsPath.path}/:conferenceRoomId(c\\d+)`);
export const ivrsPath = buildPath('/ivrs');
export const appPath = buildPath('/app');
export const acdsPath = buildPath('/channels');
export const acdPath = buildPath(
	`${acdsPath.path}/:acdId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})/:created(created)?`
);
export const acdRoutingPath = buildPath(
	`${acdsPath.path}/:acdId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})/:created(created)?/routing`
);
export const acdTeamLeadOverviewPath = buildPath('/channel-team-lead-overview');
export const acdTeamLeadDashboardPath = buildPath(
	`${acdTeamLeadOverviewPath.path}/:acdId(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})`
);
export const personalAccessTokenPath = buildPath('/personal-access-token');
export const trunksPath = buildPath('/trunks');
export const trunkPath = buildPath(`${trunksPath.path}/:trunkId(t\\d+)`);
export const locationsPath = buildPath(`/locations`);
export const phonenumbersPath = buildPath('/phonenumbers');
export const administrationPath = buildPath(`/administration`);
export const evnPath = buildPath(`${administrationPath.path}/evn`);

export const paymentPath = buildPath(`${administrationPath.path}/payment`);
export const invoicePath = buildPath(`${administrationPath.path}/invoices`);
export const billingAddressPath = buildPath(
	`${administrationPath.path}/invoices/dialog/billingAddress`
);
export const invoicesPath = buildPath(`${administrationPath.path}/invoices/settings/invoices`);
export const creditingPath = buildPath(`${administrationPath.path}/payment/dialog/singlePayment`);
export const autoCreditingPath = buildPath(
	`${administrationPath.path}/payment/dialog/auto-crediting`
);
export const baseContractPath = buildPath(`${administrationPath.path}/tariff`);
export const accountSettingsPath = buildPath(`${administrationPath.path}/settings`);
export const reportsPath = buildPath('/reports');
export const reportPath = buildPath(`${reportsPath.path}/:reportId`);
export const samlSsoPath = buildPath('/saml-sso');
export const forcePath = buildPath('/force');
export const forceChangePasswordPath = buildPath(`${forcePath.path}/changepassword`);
export const presencePath = buildPath('/presence');
export const softphonePath = buildPath('/phone');
export const sipgateIoPath = buildPath('/io');
export const apiClientsPath = buildPath('/api-clients');
export const apiClientPath = buildPath(`${apiClientsPath.path}/:apiClientId`);
export const saferPayCallbackPath = buildPath('/saferpay/:result(success|failure)');
export const googleContactCallbackPath = buildPath('/google-contact-callback');
export const welcomePathUk = buildPath('/welcome');
export const welcomePath = buildPath('/willkommen');
export const affiliatePath = buildPath('/affiliate');
export const affiliateSettingsPath = buildPath('/affiliate-settings');
export const contactsPath = buildPath('/contacts');
export const contactDetailPath = buildPath(`${contactsPath.path}/:contactId`);

type TeamWebPathDefinition<Path extends `/${string}`> = Omit<PathDefinition<Path>, 'path'>;

const buildTeamWebPath = <Path extends `/${string}`>(path: Path): TeamWebPathDefinition<Path> => ({
	build: (params?: ExtractRouteParams<Path>) => generatePath(path, params),
	regex: pathToRegexp(path, { end: false }),
});

export const numbersPath = buildTeamWebPath('/team/settings/phonenumbers');

export const ivrPath = buildTeamWebPath('/team/settings/ivr/phone/ivr/:fullExtensionId');

export const createTrunkPath = buildTeamWebPath('/team/settings/trunking/new');
export const createIvrPath = buildTeamWebPath('/team/settings/ivr/new/');

export const portingsPath = buildTeamWebPath('/team/settings/portednumbers');
export const mobilePortingsPath = buildTeamWebPath('/team/settings/mobilenumberimport');

const accountSectionUrls = [
	groupsPath.regex,
	usersPath.regex,
	trunksPath.regex,
	locationsPath.regex,
	hardwarePath.regex,
	microsoftTeamsPath.regex,
	administrationPath.regex,
	affiliatePath.regex,
	accountManagementPath.regex,
	conferenceRoomsPath.regex,
	ivrsPath.regex,
	appPath.regex,
	acdsPath.regex,
	telephonySettingsPath.regex,
	unlinkedDevicesPath.regex,
	mobileTelephonyPath.regex,
	samlSsoPath.regex,
	reportsPath.regex,
	numbersPath.regex,
	phonenumbersPath.regex,
	portingsPath.regex,
	mobilePortingsPath.regex,
	ivrPath.regex,
	affiliatePath.regex,
	affiliateSettingsPath.regex,
	sipgateIoPath.regex,
	apiClientPath.regex,
	apiClientsPath.regex,

	pathToRegexp(`/team/settings/trunking`, { end: false }),
	pathToRegexp(`/team/settings/numbers`, { end: false }),
	pathToRegexp(`/team/settings/setup`),
];

export const isAccountSection = (path: string) => {
	return accountSectionUrls.some(url => url.test(path));
};
