import { DateTime } from 'luxon';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ReduxState } from '../../types';
import { BillingAddress, ItemizedBillEntryTypeOption, ItemizedBillSettings } from './types';

const shouldFetchAccountStatement = (state: ReduxState) =>
	!state.accounting.accountStatement.fetching && !state.accounting.accountStatement.fetched;

const shouldFetchBillingAddress = (state: ReduxState) =>
	!state.accounting.billingAddress.fetching && !state.accounting.billingAddress.fetched;

const shouldFetchInvoices = (state: ReduxState) =>
	!state.accounting.invoices.fetching && !state.accounting.invoices.fetched;

export const fetchAccountStatement = createAsyncThunk(
	'accounting/fetchAccountStatement',
	async ({
		periodStart,
		periodEnd,
		creditingTypeFilters,
	}: {
		periodStart: DateTime;
		periodEnd: DateTime;
		creditingTypeFilters: string[];
		forceFetch?: boolean;
	}) => api.getAccountStatement(periodStart, periodEnd, creditingTypeFilters),
	{
		condition: ({ forceFetch }, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchAccountStatement(getState());
		},
	}
);

export const fetchBillingAddress = createAsyncThunk(
	'accounting/fetchBillingAddress',
	async () => api.getBillingAddress(),
	{
		condition: (_, { getState }) => shouldFetchBillingAddress(getState()),
	}
);

export const fetchInvoices = createAsyncThunk(
	'accounting/fetchInvoices',
	async (_?: { forceFetch?: boolean }) => {
		const invoices = await api.getInvoices();
		return {
			invoices: invoices.invoices.sort((a, b) => b.date.localeCompare(a.date)),
		};
	},
	{
		condition: (props, { getState }) =>
			(props && props.forceFetch) || shouldFetchInvoices(getState()),
	}
);

export const updateBillingAddress = createAsyncThunk(
	'accounting/updateBillingAddress',
	async (billingAddress: Partial<BillingAddress>) => api.patchBillingAddress(billingAddress)
);

export const recreateInvoice = createAsyncThunk(
	'accounting/recreateInvoice',
	async (invoiceId: string) => api.recreateInvoice(invoiceId)
);

export const fetchItemizedBillingEntries = createAsyncThunk(
	'accounting/fetchItemizedBillingEntries',
	async ({
		periodStart,
		periodEnd,
		types,
		webusers,
	}: {
		periodStart: string;
		periodEnd: string;
		types?: ItemizedBillEntryTypeOption[];
		webusers?: string[];
	}) => api.fetchItemizedBillingEntries(periodStart, periodEnd, types, webusers)
);

export const fetchItemizedBillingSettings = createAsyncThunk(
	'accounting/fetchItemizedBillingSettings',
	async () => api.getItemizedBillingSettings()
);

export const updateItemizedBillingSettings = createAsyncThunk(
	'accounting/updateItemizedBillingSettings',
	async (settings: ItemizedBillSettings) => api.setItemizedBillingSettings(settings)
);

export const fetchBillingFlags = createAsyncThunk('accounting/fetchBillingFlags', async () =>
	api.getBillingFlags()
);
