/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { createSlice } from '@reduxjs/toolkit';
import {
	cancelContract,
	deleteRoutings,
	fetchPhonenumbers,
	revokeContractCancellation,
	setRoutings,
} from './actions';
import {
	GermanLandlineProlongationChild,
	Phonenumber,
	PhonenumbersState,
	isPhonenumberWithChildren,
	PhonenumberContract,
	Porting,
	GermanPreviewNumber,
	GermanLandlineNumber,
	GermanLandlineProlongationParent,
	GermanLandlineNumberBlock,
	GermanMobileNumber,
	GermanMobileBlock,
	UKLandlineNumber,
	UKNonGeographicNumber,
	UKLandlineNumberGroup,
	InternationalNumber,
	UnknownNumber,
	UnknownNumberBlock,
	PhonenumberCancellation,
	UnknownProlongationParent,
} from './types';
import {
	ApiPhonenumber,
	ApiPhonenumberContract,
	ApiPhonenumberRouting,
	ApiPorting,
} from '../../../api/types/phonenumbers';

const mapApiContractToContract = (apiContract: ApiPhonenumberContract): PhonenumberContract => {
	return {
		contractId: apiContract.contractId,
		nextCancellationDate: apiContract.nextCancellationDate
			? DateTime.fromISO(apiContract.nextCancellationDate)
			: null,
		cancellation: apiContract.cancellation
			? {
					terminationDate: DateTime.fromISO(apiContract.cancellation.terminationDate),
					revocable: apiContract.cancellation.revocable,
				}
			: null,
		product: {
			productId: apiContract.product.productId,
			productName: apiContract.product.productName,
			cost: {
				amount: {
					amount: apiContract.product.recurringFee.amountInTenThousandths,
					currency: apiContract.product.recurringFee.currency,
					fraction: 10_000,
				},
				isNetto: apiContract.product.recurringFee.net,
				interval: 'monthly',
			},
		},
	};
};

const mapApiPortingToPorting = (apiPorting: ApiPorting | null): Porting | null => {
	if (!apiPorting) return null;

	if (apiPorting.state === 'UNKNOWN') {
		return apiPorting;
	}

	if (apiPorting.state === 'OPT_IN') {
		return {
			...apiPorting,
			optInUntil: DateTime.fromISO(apiPorting.optInUntil),
		};
	}

	return {
		...apiPorting,
		date: DateTime.fromISO(apiPorting.date),
	};
};

const mapApiPhonenumberToPhonenumber = (apiNumber: ApiPhonenumber): Phonenumber => {
	const baseProperties = {
		e164Number: apiNumber.e164Number,
		routing: apiNumber.routing,
		contract: apiNumber.contract ? mapApiContractToContract(apiNumber.contract) : null,
	};

	switch (apiNumber.type) {
		case 'GERMAN_LANDLINE_PREVIEW':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies GermanPreviewNumber;
		case 'GERMAN_LANDLINE':
			return {
				...baseProperties,
				type: apiNumber.type,
				addressId: apiNumber.addressId,
				porting: mapApiPortingToPorting(apiNumber.porting),
			} satisfies GermanLandlineNumber;
		case 'GERMAN_LANDLINE_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n =>
						mapApiPhonenumberToPhonenumber(n) as
							| GermanLandlineNumber
							| GermanLandlineProlongationParent
				),
			} satisfies GermanLandlineNumberBlock;
		case 'GERMAN_LANDLINE_PROLONGATION_PARENT':
			return {
				...baseProperties,
				type: apiNumber.type,
				porting: mapApiPortingToPorting(apiNumber.porting),
				baseContract: apiNumber.baseContract
					? mapApiContractToContract(apiNumber.baseContract)
					: null,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as GermanLandlineProlongationChild
				),
			} satisfies GermanLandlineProlongationParent;
		case 'GERMAN_LANDLINE_PROLONGATION_CHILD':
			return {
				...baseProperties,
				type: apiNumber.type,
				contract: null,
				addressId: apiNumber.addressId,
			} satisfies GermanLandlineProlongationChild;
		case 'GERMAN_MOBILE':
			return {
				...baseProperties,
				type: apiNumber.type,
				porting: mapApiPortingToPorting(apiNumber.porting),
			} satisfies GermanMobileNumber;
		case 'GERMAN_MOBILE_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as GermanMobileNumber
				),
			} satisfies GermanMobileBlock;
		case 'UK_LANDLINE':
		case 'UK_NON_GEOGRAPHIC':
			return {
				...baseProperties,
				type: apiNumber.type,
				addressId: apiNumber.addressId,
			} satisfies UKLandlineNumber | UKNonGeographicNumber;
		case 'UK_LANDLINE_GROUP':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(n => mapApiPhonenumberToPhonenumber(n) as UKLandlineNumber),
			} satisfies UKLandlineNumberGroup;
		case 'INTERNATIONAL':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies InternationalNumber;
		case 'UNKNOWN':
			return {
				...baseProperties,
				type: apiNumber.type,
			} satisfies UnknownNumber;
		case 'UNKNOWN_BLOCK':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n =>
						mapApiPhonenumberToPhonenumber(n) as
							| GermanLandlineNumber
							| GermanLandlineProlongationParent
							| GermanMobileNumber
							| InternationalNumber
							| UKLandlineNumber
							| UnknownNumber
				),
			} satisfies UnknownNumberBlock;
		case 'UNKNOWN_PROLONGATION':
			return {
				...baseProperties,
				type: apiNumber.type,
				numbers: apiNumber.numbers.map(
					n => mapApiPhonenumberToPhonenumber(n) as UnknownNumber | GermanLandlineProlongationChild
				),
			} satisfies UnknownProlongationParent;
	}
};

const updateRouting = (
	phonenumbers: Phonenumber[],
	changedE164Numbers: string[],
	newRouting: ApiPhonenumberRouting | null
) => {
	phonenumbers.forEach(phonenumber => {
		if (changedE164Numbers.includes(phonenumber.e164Number)) {
			phonenumber.routing = newRouting;
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			updateRouting(phonenumber.numbers, changedE164Numbers, newRouting);
		}
	});
};

const updateContractCancellation = (
	phonenumbers: Phonenumber[],
	changedContractId: string,
	newCancellation: PhonenumberCancellation | null
) => {
	phonenumbers.forEach(phonenumber => {
		if (
			phonenumber.type === 'GERMAN_LANDLINE_PROLONGATION_PARENT' &&
			phonenumber.contract &&
			phonenumber.baseContract &&
			changedContractId === phonenumber.baseContract.contractId
		) {
			phonenumber.baseContract.cancellation = newCancellation;
			phonenumber.contract.cancellation = newCancellation;
		}

		if (changedContractId === phonenumber.contract?.contractId) {
			phonenumber.contract.cancellation = newCancellation;

			if (isPhonenumberWithChildren(phonenumber)) {
				phonenumber.numbers.forEach(childPhonenumber => {
					if (childPhonenumber.contract) {
						childPhonenumber.contract.cancellation = newCancellation;
					}
				});
			}
		}

		if (isPhonenumberWithChildren(phonenumber)) {
			updateContractCancellation(phonenumber.numbers, changedContractId, newCancellation);
		}
	});
};

const initialState: PhonenumbersState = {
	fetched: false,
	fetching: false,
	items: [],
};

export const reducer = createSlice({
	name: 'phonenumbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchPhonenumbers.pending, state => {
			return { ...state, fetching: true };
		});
		builder.addCase(fetchPhonenumbers.fulfilled, (state, action) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				items: action.payload.map(mapApiPhonenumberToPhonenumber).map(number => {
					if ('numbers' in number) {
						number.numbers.sort((a, b) => {
							return a.e164Number.localeCompare(b.e164Number);
						});
					}

					return number;
				}),
			};
		});
		builder.addCase(setRoutings.fulfilled, (state, action) => {
			updateRouting(state.items, action.meta.arg.e164Numbers, action.payload);
		});
		builder.addCase(deleteRoutings.pending, (state, action) => {
			updateRouting(state.items, action.meta.arg.e164Numbers, null);
		});
		builder.addCase(cancelContract.fulfilled, (state, action) => {
			updateContractCancellation(state.items, action.meta.arg.contractId, {
				terminationDate: DateTime.fromISO(action.payload.terminationDate),
				revocable: action.payload.revocable,
			});
		});
		builder.addCase(revokeContractCancellation.pending, (state, action) => {
			updateContractCancellation(state.items, action.meta.arg.contractId, null);
		});
	},
}).reducer;
