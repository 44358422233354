import { createSlice, UnknownAction } from '@reduxjs/toolkit';
import { handleActions } from '../..';
import { setFaxlineAlias } from '../faxlines';
import { setPhonelineAlias } from '../phonelines';
import { NumberState } from './types';
import {
	ApiInternationalNumber,
	ApiLandlineNumber,
	ApiMobileNumber,
} from '../../../api/types/numbers';
import { sortPhoneNumbers } from './selectors';
import * as actions from './actions';
import { setGroupAlias } from '../groups';
import { createAcd, setAcdNumbers, deleteAcd } from '../../slices/acds';
import { moveAddress } from '../addresses';

const initialState: NumberState = {
	items: [],
	fetched: false,
	fetching: false,
};

const rtkReducer = createSlice({
	name: 'numbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(createAcd.fulfilled, (state, { payload }) => ({
				...state,
				items: state.items.map(number => {
					if (payload.phoneNumbers.includes(number.number)) {
						return {
							...number,
							endpointId: payload.id,
							endpointAlias: payload.name,
						};
					}
					return number;
				}),
			}))
			.addCase(setAcdNumbers.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(number => {
					if (action.meta.arg.phoneNumbers.includes(number.number)) {
						return {
							...number,
							endpointId: action.meta.arg.id,
							endpointAlias: action.meta.arg.name,
						};
					}

					if (number.endpointId === action.meta.arg.id) {
						return {
							...number,
							endpointId: '',
							endpointAlias: '',
						};
					}

					return number;
				}),
			}))
			.addCase(deleteAcd.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(number => {
					if (number.endpointId === action.meta.arg.acdId) {
						return {
							...number,
							endpointId: '',
							endpointAlias: '',
						};
					}

					return number;
				}),
			}));
	},
}).reducer;

const legacyReducer = handleActions<
	NumberState,
	PossibleActions<
		| typeof actions
		| typeof setPhonelineAlias
		| typeof setFaxlineAlias
		| typeof setGroupAlias
		| typeof moveAddress
	>
>(
	{
		NUMBERS_FETCH_ACCESSIBLE_PENDING: state => ({ ...state, fetching: true }),
		NUMBERS_FETCH_ACCESSIBLE_SUCCESS: (state, { payload }) => {
			const withoutQuickDials = payload[0].items.filter(n => n.type !== 'QUICKDIAL') as (
				| ApiMobileNumber
				| ApiLandlineNumber
				| ApiInternationalNumber
			)[];

			return {
				...state,
				fetched: true,
				fetching: false,
				items: sortPhoneNumbers(
					withoutQuickDials.map(number => {
						const acd = payload[1].items.find(acdItem =>
							acdItem.phoneNumbers.includes(number.number)
						);

						return {
							...number,
							endpointId: acd ? acd.id : number.endpointId,
							endpointAlias: acd ? acd.name : number.endpointAlias,
						};
					})
				),
			};
		},

		NEW_ADDRESS_MOVE_SUCCESS: (state, { payload }) =>
			payload.numbers
				? {
						...state,
						fetched: true,
						items: sortPhoneNumbers(
							payload.numbers.filter(n => n.type !== 'QUICKDIAL') as (
								| ApiMobileNumber
								| ApiLandlineNumber
								| ApiInternationalNumber
							)[]
						),
					}
				: state,
		FAXLINE_SET_ALIAS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => ({
				...number,
				endpointAlias: number.endpointId === data.faxlineId ? data.alias : number.endpointAlias,
			})),
		}),
		PHONELINE_SET_ALIAS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(number => ({
				...number,
				endpointAlias: number.endpointId === data.phonelineId ? data.alias : number.endpointAlias,
			})),
		}),
		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				items: state.items.map(number => {
					if (number.endpointId === data.groupId) {
						return {
							...number,
							endpointAlias: data.alias,
						};
					}

					return number;
				}),
			};
		},
	},
	initialState
);

export const reducer = (state: NumberState, action: UnknownAction) => {
	return rtkReducer(legacyReducer(state, action), action);
};
