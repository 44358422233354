import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	cancelContract,
	revokeContractCancellation,
	deleteRoutings,
	fetchPhonenumbers,
	forceFetchPhonenumbers,
	setRoutings,
	releaseMobilePhonenumber,
} from './actions';
import { SliceData, useAction } from '../../index';
import { Phonenumber, PhonenumberProduct } from './types';
import api from '../../../api';

export const usePhonenumbers = (): SliceData<Phonenumber[]> => {
	const dispatch = useDispatch();
	const phonenumbers = useSelector(state => state.phonenumbers);

	useEffect(() => {
		dispatch(fetchPhonenumbers());
	}, [dispatch]);

	if (phonenumbers.fetched) {
		return {
			data: phonenumbers.items,
			fetched: true,
		};
	}

	return {
		data: null,
		fetched: false,
	};
};

export const usePhonenumbersActions = () => ({
	setRoutings: useAction(setRoutings),
	deleteRoutings: useAction(deleteRoutings),
	cancelContract: useAction(cancelContract),
	revokeContractCancellation: useAction(revokeContractCancellation),
	forceFetchPhonenumbers: useAction(forceFetchPhonenumbers),
	releaseMobilePhonenumber: useAction(releaseMobilePhonenumber),
});

export const usePhonenumberProducts = (): SliceData<PhonenumberProduct[]> => {
	const [products, setProducts] = useState<PhonenumberProduct[] | null>(null);

	useEffect(() => {
		const fetchPhonenumberProducts = async () => {
			try {
				const response = await api.getPhonenumberProducts();
				if (response && response.items) {
					// TODO: Implement multiple cost intervals
					const transformedProducts: PhonenumberProduct[] = response.items.map(option => ({
						id: option.productId,
						type: option.type,
						productName: option.productName,
						recurringCost: {
							amount: {
								amount: option.recurringFee.amountInTenThousandths,
								currency: option.recurringFee.currency,
								fraction: 10_000,
							},
							interval: 'monthly',
							isNetto: option.recurringFee.net,
						},
						setupCost: {
							amount: {
								amount: option.setupFee.amountInTenThousandths,
								currency: option.setupFee.currency,
								fraction: 10_000,
							},
							interval: 'onetime',
							isNetto: option.setupFee.net,
						},
					}));
					setProducts(transformedProducts);
				} else {
					setProducts(null);
				}
			} catch (error) {
				setProducts(null);
			}
		};

		fetchPhonenumberProducts();
	}, []);

	if (products === null) {
		return {
			fetched: false,
			data: null,
		};
	}

	return {
		fetched: true,
		data: products,
	};
};
