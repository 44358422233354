import api from '../../../api';
import { ApiRingingOrderSettings } from '../../../api/types/acd';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchAcds = createAsyncThunk(
	'acds/fetch',
	async (_?: { force: boolean }) => api.getAcds(),
	{
		condition: (_arg, { getState }) =>
			(!getState().acds.fetched && !getState().acds.fetching) || _arg?.force,
	}
);

export const createAcd = createAsyncThunk(
	'acds/create',
	async (data: { name: string; phonenumbers: string[]; agentIds: string[] }) =>
		api.createAcd(data.name, data.phonenumbers, data.agentIds)
);

export const setAcdAgents = createAsyncThunk(
	'acds/setAcdAgents',
	async (data: { acdId: string; agentIds: string[] }) => api.setAcdAgents(data.acdId, data.agentIds)
);

export const setAcdNumbers = createAsyncThunk(
	'acds/setAcdNumbers',
	async (data: { id: string; name: string; phoneNumbers: string[] }) =>
		api.setAcdNumbers(data.id, data.phoneNumbers)
);

export const setAcdAgentDevices = createAsyncThunk(
	'acds/setAcdAgentDevices',
	async (data: { acdId: string; agentId: string; deviceIds: string[] }) =>
		api.setAcdAgentDevices(data.acdId, data.agentId, data.deviceIds)
);

export const setAcdName = createAsyncThunk(
	'acds/setAcdName',
	async (data: { acdId: string; name: string }) => api.setAcdName(data.acdId, data.name)
);

export const deleteAcd = createAsyncThunk('acds/delete', async (data: { acdId: string }) =>
	api.deleteAcd(data.acdId)
);

export const setAcdQueueWaitingAudioFile = createAsyncThunk(
	'acds/setAcdQueueWaitingAudioFile',
	async (data: { acdId: string; audioFileId: string }) =>
		api.setAcdQueueWaitingAudioFile(data.acdId, data.audioFileId)
);

export const setAcdGreetingAudioFile = createAsyncThunk(
	'acds/setAcdGreetingAudioFile',
	async (data: { acdId: string; audioFileId: string | null }) =>
		api.setAcdGreetingAudioFile(data.acdId, data.audioFileId)
);

export const setAcdFollowUpTime = createAsyncThunk(
	'acds/setAcdFollowUpTime',
	async (data: { acdId: string; followUpTime: number }) =>
		api.setAcdFollowUpTime(data.acdId, data.followUpTime)
);

export const setAcdRingTime = createAsyncThunk(
	'acds/setAcdRingTime',
	async (data: { acdId: string; ringTime: number }) => api.setAcdRingTime(data.acdId, data.ringTime)
);

export const setAcdRingingOrder = createAsyncThunk(
	'acds/setAcdRingingOrder',
	async (data: { acdId: string; ringingOrder: ApiRingingOrderSettings }) =>
		api.setAcdRingingOrder(data.acdId, data.ringingOrder)
);

export const setAcdTeamLeads = createAsyncThunk(
	'acds/setAcdTeamLeads',
	async (data: { acdId: string; teamLeadIds: string[] }) =>
		api.setAcdTeamLeads(data.acdId, data.teamLeadIds)
);
