import { createSlice } from '@reduxjs/toolkit';
import { AcdState } from './types';
import {
	createAcd,
	deleteAcd,
	fetchAcds,
	setAcdAgentDevices,
	setAcdAgents,
	setAcdFollowUpTime,
	setAcdGreetingAudioFile,
	setAcdName,
	setAcdNumbers,
	setAcdQueueWaitingAudioFile,
	setAcdRingingOrder,
	setAcdRingTime,
	setAcdTeamLeads,
} from './actions';

import { deleteAcdAudioFile } from '../acdAudioFiles/actions';
import { ApiError } from '../../../api';

const initialState: AcdState = {
	items: [],
	fetched: false,
	fetching: false,
};

export const reducer = createSlice({
	name: 'acds',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchAcds.pending, state => ({ ...state, fetching: true }))
			.addCase(fetchAcds.fulfilled, (state, action) => {
				const newItems = [
					...action.payload.items.map(acd => ({
						id: acd.id,
						name: acd.name,
						phoneNumbers: acd.phoneNumbers,
						agents: acd.agents,
						teamLeadIds: acd.teamLeadIds,
						settings: {
							...acd.settings,
						},
						createdAt: acd.createdAt,
					})),
				];
				newItems.sort((a, b) => a.name.localeCompare(b.name));

				return {
					...state,
					fetched: true,
					fetching: false,
					items: newItems,
				};
			})
			.addCase(fetchAcds.rejected, (state, action) => {
				if (action.payload instanceof ApiError && action.payload.status !== 403) {
					return state;
				}

				return {
					...state,
					fetched: true,
					fetching: false,
				};
			})
			.addCase(createAcd.fulfilled, (state, { payload }) => ({
				...state,
				items: [
					...state.items,
					{
						id: payload.id,
						name: payload.name,
						phoneNumbers: payload.phoneNumbers ? payload.phoneNumbers : [],
						agents: payload.agents,
						teamLeadIds: payload.teamLeadIds,
						settings: payload.settings,
						createdAt: payload.createdAt,
					},
				],
			}))
			.addCase(setAcdNumbers.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						return {
							...acd,
							phoneNumbers:
								acd.id === action.meta.arg.id ? action.meta.arg.phoneNumbers : acd.phoneNumbers,
						};
					}),
				],
			}))
			.addCase(setAcdAgents.fulfilled, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						return {
							...acd,
							agents:
								acd.id === action.meta.arg.acdId
									? action.payload.items.map(agent => ({
											id: agent.id,
											deviceIds: agent.deviceIds,
											state: agent.state,
										}))
									: acd.agents,
						};
					}),
				],
			}))
			.addCase(setAcdAgentDevices.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}
						return {
							...acd,
							agents: acd.agents.map(agent => {
								return {
									...agent,
									deviceIds:
										agent.id === action.meta.arg.agentId
											? action.meta.arg.deviceIds
											: agent.deviceIds,
								};
							}),
						};
					}),
				],
			}))
			.addCase(setAcdName.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							name: action.meta.arg.name,
						};
					}),
				],
			}))
			.addCase(deleteAcd.pending, (state, action) => ({
				...state,
				items: [...state.items.filter(acd => acd.id !== action.meta.arg.acdId)],
			}))
			.addCase(setAcdQueueWaitingAudioFile.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								queue: {
									...acd.settings.queue,
									waitingAudioFileId: action.meta.arg.audioFileId,
								},
							},
						};
					}),
				],
			}))
			.addCase(setAcdGreetingAudioFile.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								greetingAudioFileId: action.meta.arg.audioFileId,
							},
						};
					}),
				],
			}))
			.addCase(setAcdFollowUpTime.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								agents: {
									...acd.settings.agents,
									followUpTime: action.meta.arg.followUpTime,
								},
							},
						};
					}),
				],
			}))
			.addCase(setAcdRingTime.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								agents: {
									...acd.settings.agents,
									ringTime: action.meta.arg.ringTime,
								},
							},
						};
					}),
				],
			}))
			.addCase(setAcdRingingOrder.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								ringingOrder: action.meta.arg.ringingOrder,
							},
						};
					}),
				],
			}))
			.addCase(setAcdTeamLeads.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId) {
							return acd;
						}

						return {
							...acd,
							teamLeadIds: action.meta.arg.teamLeadIds,
						};
					}),
				],
			}))
			.addCase(deleteAcdAudioFile.pending, (state, action) => ({
				...state,
				items: [
					...state.items.map(acd => {
						if (acd.id !== action.meta.arg.acdId || !action.meta.arg.defaultAudioFileId) {
							return acd;
						}

						return {
							...acd,
							settings: {
								...acd.settings,
								queue: {
									...acd.settings.queue,
									waitingAudioFileId:
										action.meta.arg.audioFileType === 'QUEUE_WAITING'
											? action.meta.arg.defaultAudioFileId
											: acd.settings.queue.waitingAudioFileId,
								},
							},
						};
					}),
				],
			}));
	},
}).reducer;
